<template lang="pug">
  div.row
    div.col-12
      div.table-responsive
        table.table.table-striped.ausers-table
          thead
            tr
              th At
              th Name
              th Email
          tbody
            tr(v-for="au in ausers", :key="au.id")
              td {{ au.at | moment }}
              td {{ au.name }}
              td {{ au.email }}
</template>

<script>
import moment from 'moment';

export default {
  name:"AttemptUsers",
  data (){
    return {
      ausers:[
      ]
    }
  },
  mounted (){
    this.axios("/api/attemptusers")
    .then((res)=>{
      this.ausers = res.data;
    });
  },
  methods: {
  },
  filters: {
    moment (timestamp) {
      if (timestamp)
        return moment(new Date(timestamp*1000)).format('YYYY/MM/DD HH:mm');
      else
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.ausers-table {
  th, td {
    vertical-align: middle;
  }
}
</style>